import React, { useRef, useEffect } from "react";
import "./../styles/popup.css";
import RewardsImage from "../assets/rewards-pop-up.png";
import CloseBtnSvg from "../assets/close-btn.svg";
import CloseWhiteBtnSvg from "../assets/CloseWhiteBtnSvg.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RewardsPopup = ({ isOpen, handleClosePopup }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClosePopup]);
  if (!isOpen) return null;

  return (
    <>
      <div className="blurry-background" onClick={handleClosePopup}></div>
      <div className="popup-container" ref={popupRef}>
        <img src={RewardsImage} alt="Rewards" className="popup-image" />
        <div className="popup-content">
          <div className="popup-close-button" onClick={handleClosePopup}>
            <img src={CloseBtnSvg} alt="CloseBtnSvg" />
          </div>
          <div className="popup-title">{t("greeting")}</div>
          <div className="popup-subtitle">{t("redeemMessage")}</div>
          <div className="popup-button-container">
            <button
              className="popup-button"
              type="submit"
              onClick={() => {
                navigate("/products");
              }}
            >
              {t("shopNow")}
            </button>
          </div>
        </div>
        <div className="popup-close-button-white" onClick={handleClosePopup}>
          <img src={CloseWhiteBtnSvg} alt="CloseWhiteBtnSvg" />
        </div>
      </div>
    </>
  );
};

export default RewardsPopup;
