import React from "react";
import Input from "scenes/signUp/Input";
import { useTranslation } from "react-i18next";

const ForgetPasswordPopup = ({
  popup,
  handleClosePopup,
  handleForgetPhoneInput,
  forgetPhone,
  handleForgetPhoneSubmit,
  error2,
}) => {
  const { t } = useTranslation();

  if (!popup) return null;

  return (
    <>
      <div className="blurry-background" onClick={handleClosePopup}></div>
      <div className="forget-password-pop-up">
        <div className="close-btn" onClick={handleClosePopup}>
          &times;
        </div>
        <div>{t("reset_password")}</div>
        <span style={{ color: "#818181", fontWeight: "400" }}>
          {t("please_enter_phone")}
        </span>

        <Input
          name="phone"
          type="text"
          onChange={handleForgetPhoneInput}
          isValid={forgetPhone.isValid}
          isTouched={forgetPhone.isTouched}
          placeholder={t("phone_placeholder")}
          value={forgetPhone.value}
          errorMsg={t("valid_phone_error")}
        />

        <button
          className="submit-login"
          type="submit"
          onClick={handleForgetPhoneSubmit}
          style={{ marginTop: "0px", height: "60px" }}
        >
          {t("send_request")}
        </button>
        {error2 && <div className="error-message-login">{error2}</div>}
      </div>
    </>
  );
};

export default ForgetPasswordPopup;
