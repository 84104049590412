import React from "react";

export default function ProgressBar({ percentage, color }) {
  const progressBarStyle = {
    height: "8px",
    width: "100%",
    background: color,
    borderRadius: "10px",
  };
  return <div style={progressBarStyle}></div>;
}
