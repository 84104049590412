import React, { useState, useEffect } from "react";
import Footer from "components/Footer";
import { SyncLoader } from "react-spinners";
import Error from "./../../assets/error.png";
import PopUpBackground from "./../../assets/pop-up-background.png";
import User from "User";
import "./quiz.css";
import "./../products/products.css";
import { useNavigate } from "react-router-dom";
import QuizImage from "./../../assets/quiz.png";
import QuizImageMob from "./../../assets/quiz-mob.png";
import { useTranslation } from "react-i18next";
import "../../fonts/fonts.css";

function Quiz() {
  const [quiz, setQuiz] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startQuiz, setStartQuiz] = useState(false);
  const [popup, setPopup] = useState(false);
  const [result, setResult] = useState("");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const totalQuestions = quiz ? quiz.questions.length : 0;
  const progress = (currentQuestionIndex / totalQuestions) * 100;

  useEffect(() => {
    fetch(`https://dashboard.natindamiddleeast.com/api/getQuiz/1`)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.json();
      })
      .then((data) => {
        setQuiz(data.quiz);
        setLoading(false);
      })
      .catch((error) => {
        setError("Failed to load the quiz.");
        setLoading(false);
        console.error("Failed to fetch quiz: ", error);
      });
  }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (i18n.language === "ar") {
      htmlElement.classList.add("arabic-font");
    } else {
      htmlElement.classList.remove("arabic-font");
    }
  }, [i18n.language]);

  const handleAnswerChange = (questionId, answer) => {
    setAnswers((prev) => ({ ...prev, [questionId]: answer }));
  };

  const canSubmit = () => {
    return (
      quiz?.questions.length === Object.keys(answers).length &&
      Object.keys(answers).every((key) => answers[key] !== "")
    );
  };

  const handleAction = () => {
    if (!startQuiz) {
      handleStartQuiz();
    } else if (currentQuestionIndex < quiz.questions.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    } else if (canSubmit()) {
      handleSubmit();
    } else {
      alert("Please answer all questions before submitting.");
    }
  };

  const handleSubmit = () => {
    if (canSubmit()) {
      setLoading(true);
      const url = `https://dashboard.natindamiddleeast.com/api/submitQuiz`;
      const formattedResponses = Object.entries(answers).map(
        ([questionId, answer]) => {
          const qId = parseInt(questionId, 10);
          const isOptionId = !isNaN(parseInt(answer, 10));

          return {
            question_id: qId,
            option_id: isOptionId ? parseInt(answer, 10) : null,
            text_response: isOptionId ? null : answer,
          };
        }
      );

      const payload = {
        quiz_id: 1,
        responses: formattedResponses,
      };

      const user = User.fromLocalStorage();

      let headers = {
        "Content-Type": "application/json",
      };

      if (user && user.api_token) {
        headers["Authorization"] = `Bearer ${user.api_token}`;
      }

      fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (!response.ok) throw new Error("Failed to submit quiz");
          return response.json();
        })
        .then((data) => {
          switch (data.recommendation) {
            case "Combination Skin":
              setResult("/combination-skin");
              break;
            case "Oily Skin":
              setResult("/oily-skin");
              break;
            case "Sensitive Skin":
              setResult("/sensitive-skin");
              break;
            case "Normal Skin":
              setResult("/normal-skin");
              break;
            case "Dry Skin":
              setResult("/dry-skin");
              break;
            default:
              console.error("Unknown skin type: ", data.recommendation);
              navigate("/error");
              return;
          }

          if (user) {
            setLoading(false);
            navigate(result);
          } else {
            setLoading(false);
            setPopup(true);
          }
        })
        .catch((error) => {
          alert("Failed to submit the quiz. Please try again.");
          setLoading(false);
        });
    } else {
      alert("Please answer all questions before submitting.");
    }
  };

  if (loading)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader-overlay">
          <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
        </div>
      </div>
    );

  if (error) {
    return (
      <div className="error-container">
        <img src={Error} alt="error" />
        <div className="error-text">OOPS! No quiz Found</div>
      </div>
    );
  }

  const currentQuestion = quiz.questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === quiz.questions.length - 1;

  const renderQuestionText = (text) => {
    const userName = answers["1"];
    if (!text) return "";
    return userName ? text.replace("(Name)", userName) : text;
  };

  const handleStartQuiz = () => {
    setStartQuiz(true);
  };

  if (!startQuiz) {
    return (
      <main>
        <div className="quiz-start-web-view">
          <div className="quiz-start-left">
            <div className="quiz-start-head">{t("make_easy")}</div>
            <div className="quiz-start-sub">{t("quiz_start")}</div>
            <button className="quiz-start-button" onClick={handleAction}>
              {t("Lets get started")}
            </button>
          </div>

          <div className="quiz-start-right">
            <img src={QuizImage} alt="quiz" />
          </div>
        </div>
        <div className="quiz-start-mobile-view">
          <img src={QuizImageMob} alt="QuizImageMob" />
          <div className="quiz-start-head-mob">{t("make_easy")}</div>
          <div className="quiz-start-sub-mob">{t("quiz_start")}</div>
          <button className="quiz-start-button" onClick={handleAction}>
            {t("Lets get started")}{" "}
          </button>
        </div>
        <Footer />
      </main>
    );
  }

  const handleClosePopup = () => {
    navigate(result);
    setPopup(false);
  };

  const handleLogin = () => {
    navigate("/login", { state: { fromQuiz: true, result: result } });
    setPopup(false);
  };

  const handleSignUp = () => {
    navigate("/sign-up", { state: { fromQuiz: true, result: result } });
    setPopup(false);
  };

  if (popup) {
    return (
      <>
        <div className="modal-backdrop-web">
          <div className="modal-backdrop">
            <div className="modal-content">
              <div className="modal-body">
                <button className="modal-close-btn" onClick={handleClosePopup}>
                  X
                </button>
                <div className="modal-image">
                  <img src={PopUpBackground} alt="Authenticate" />
                </div>
                <div className="modal-actions">
                  <div>
                    {t("one_step_away")}
                    <span style={{ fontStyle: "italic" }}>
                      {" "}
                      {t("glass_skin")}
                    </span>
                  </div>
                  <div className="modal-actions-sub">{t("sign_up_prompt")}</div>

                  <button
                    className="modal-actions-first-button"
                    onClick={handleLogin}
                  >
                    {t("sign_in")}
                  </button>
                  <button
                    className="modal-actions-second-button"
                    onClick={handleSignUp}
                  >
                    {t("sign_up")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="model-backdrop-mob">
          <div className="modal-image-mob">
            <img src={PopUpBackground} alt="Authenticate" />
            <div className="modal-head">
              {t("one_step_away")}
              <span style={{ fontStyle: "italic" }}> {t("glass_skin")}</span>
            </div>
            <div className="modal-sub">{t("sign_up_prompt")}</div>

            <div style={{ margin: "20px" }}>
              <button
                className="modal-actions-first-button-mob"
                onClick={handleLogin}
              >
                {t("sign_in")}
              </button>
              <button
                className="modal-actions-second-button-mob"
                onClick={handleSignUp}
              >
                {t("sign_up")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <main>
      <div className="quiz-web-view">
        <div className="quiz-question">
          {renderQuestionText(
            i18n.language === "ar"
              ? currentQuestion.question_text_ar
              : currentQuestion.question_text
          )}
        </div>

        {currentQuestion.question_type === "text" ? (
          <div className="quiz-text-input">
            <input
              type="text"
              value={answers[currentQuestion.id] || ""}
              onChange={(e) =>
                handleAnswerChange(currentQuestion.id, e.target.value)
              }
            />
          </div>
        ) : (
          <div className="quiz-options">
            {currentQuestion.options.map((option, index) => (
              <div
                key={option.id}
                className={`quiz-option ${
                  answers[currentQuestion.id] === option.id ? "selected" : ""
                }`}
                onClick={() =>
                  handleAnswerChange(currentQuestion.id, option.id)
                }
              >
                {i18n.language === "ar"
                  ? option.option_text_ar
                  : option.option_text}
              </div>
            ))}
          </div>
        )}
        <div className="quiz-back-next-container">
          <button
            style={{ backgroundColor: "transparent" }}
            onClick={() =>
              setCurrentQuestionIndex((prevIndex) => Math.max(0, prevIndex - 1))
            }
            disabled={currentQuestionIndex === 0}
          >
            {t("BACK")}
          </button>
          <button
            style={{
              backgroundColor: (!isLastQuestion ? false : !canSubmit())
                ? "FDDCCD"
                : "#FDDCCD",
              color: (!isLastQuestion ? false : !canSubmit()) ? "#000" : "#000", // change ely 3la shemal
            }}
            onClick={handleAction}
            disabled={!isLastQuestion ? false : !canSubmit()}
          >
            {isLastQuestion ? t("SUBMIT") : t("NEXT")}
          </button>
        </div>
      </div>

      <div className="quiz-mobile-view">
        <div className="quiz-progress-container">
          <div
            className="quiz-progress-bar"
            style={{ width: `${progress}%` }}
          ></div>
        </div>

        <div className="quiz-mobile-big-container">
          <div className="quiz--mobile-container">
            <div className="quiz-question">
              {renderQuestionText(
                i18n.language === "ar"
                  ? currentQuestion.question_text_ar
                  : currentQuestion.question_text
              )}
            </div>
            {/* answer  */}
            {currentQuestion.question_type === "text" ? (
              <div className="quiz-text-input">
                <input
                  type="text"
                  value={answers[currentQuestion.id] || ""}
                  onChange={(e) =>
                    handleAnswerChange(currentQuestion.id, e.target.value)
                  }
                />
              </div>
            ) : (
              <div className="quiz-options">
                {currentQuestion.options.map((option, index) => (
                  <div
                    key={option.id}
                    className={`quiz-option ${
                      answers[currentQuestion.id] === option.id
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleAnswerChange(currentQuestion.id, option.id)
                    }
                  >
                    {i18n.language === "ar"
                      ? option.option_text_ar
                      : option.option_text}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="quiz--mobile-container">
            <button
              style={{
                backgroundColor: (!isLastQuestion ? false : !canSubmit())
                  ? "#FDDCCD"
                  : "#FDDCCD",
                color: (!isLastQuestion ? false : !canSubmit())
                  ? "#000"
                  : "#000", // Optional: change text color for disabled state
              }}
              onClick={handleAction}
              disabled={!isLastQuestion ? false : !canSubmit()}
            >
              {isLastQuestion ? t("SUBMIT") : t("NEXT")}
            </button>
            <button
              style={{ backgroundColor: "transparent" }}
              onClick={() =>
                setCurrentQuestionIndex((prevIndex) =>
                  Math.max(0, prevIndex - 1)
                )
              }
              disabled={currentQuestionIndex === 0}
            >
              {t("BACK")}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}

export default Quiz;
