import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; // Import Navigate
import AuthContext from "./AuthContext"; // Correct import path
import Products from "scenes/products";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import HomePage from "./scenes/Home/home.jsx";
import SingleProduct from "scenes/product";
import SignUp from "scenes/signUp/sign-up";
import { Helmet } from "react-helmet";
import Reviews from "scenes/reviews";
import New from "scenes/new";
import Verification from "scenes/verification/verfication";
import Login from "scenes/login";
import Profile from "scenes/profile";
import Cart from "scenes/Cart/cart";
import UploadImageForm from "scenes/upload/upload";
import OrderInfo from "scenes/OrderInfo/orderInfo.jsx";
import Shipping from "scenes/Shipping/shipping";
import OrderSuccess from "scenes/OrderSuccess/OrderSuccess";
import PerviewProduct from "scenes/perview";
import BundlesAndGifts from "scenes/products-gift-sets";
import Payment from "scenes/Payment/payment";
import Quiz from "scenes/quiz";
import TermsAndConditions from "scenes/terms";
import DrySkin from "scenes/dry_skin";
import OilySkin from "scenes/oily_skin";
import AboutUs from "scenes/about_us";
import SensitiveSkin from "scenes/sensitive_skin";
import NormalSkin from "scenes/normal_skin";
import CombinationSkin from "scenes/combination-skin";
import Rewards from "scenes/rewards";
import OrderFailed from "scenes/OrderFailed/orderFailed";
import ScrollToTop from "ScrollToTop";
import Accessories from "scenes/Accessories/Accessories";
import PaymentResult from "scenes/payment-return";
import FAQs from "scenes/FAQs/FAQs";
import Receipts from "scenes/receipt/receipt";
import Privacy from "scenes/privacy";
import SkinJournal from "scenes/skinJournal/skinJournal";
import Policies from "scenes/Policies/policies";
import ReactGA from "react-ga4";
import "./i18n";
import { useTranslation } from "react-i18next";
import TrackOrder from "scenes/track_order";

function App() {
  const { isAuthenticated } = useContext(AuthContext);
  const { i18n } = useTranslation();

  // TiktokPixel.init('IDCP1P7RRC77UF8F9DCIMG');
  ReactGA.initialize("Your-Measurement-ID");

  useEffect(() => {
    document.documentElement.dir = i18n.language === "ar" ? "rtl" : "ltr";

    ReactGA.initialize("G-3KMJ2EVWXY");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [i18n.language]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <div className="app">
          <Navbar />
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/products" element={<Products />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/product/:name/:id" element={<SingleProduct />} />
            <Route path="/uploadImageForm" element={<UploadImageForm />} />
            <Route path="/cart/:id" element={<OrderInfo />} />
            <Route path="/cart/:id/shipping" element={<Shipping />} />
            <Route path="/perviewproduct/:id" element={<PerviewProduct />} />
            <Route path="/gift-sets" element={<BundlesAndGifts />} />
            <Route path="/cart/:id/shipping/:id2" element={<Payment />} />
            <Route path="/track-my-order/:id" element={<TrackOrder />} />

            <Route path="/quiz" element={<Quiz />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/dry-skin" element={<DrySkin />} />
            <Route path="/oily-skin" element={<OilySkin />} />
            <Route path="/sensitive-skin" element={<SensitiveSkin />} />
            <Route path="/normal-skin" element={<NormalSkin />} />
            <Route path="/combination-skin" element={<CombinationSkin />} />
            <Route path="/order/orderConfirmation/:id" element={<OrderSuccess />} />
            <Route path="/cart/orderFailed" element={<OrderFailed />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/payment-result" element={<PaymentResult />} />
            <Route path="/FAQs" element={<FAQs />} />
            <Route path="/receipts" element={<Receipts />} />
            <Route path="/receipts/:id" element={<Receipts />} />
            <Route path="/accessories" element={<Accessories />} />
            <Route path="/privacy-and-terms" element={<Privacy />} />
            <Route path="/skinJournal" element={<SkinJournal />} />

            <Route path="/policies" element={<Policies />} />
            <Route path="/products/:id/reviews" element={<Reviews />} />

            {/* Protected routes with conditional rendering */}
            {isAuthenticated ? (
              <>
                <Route path="/products/:id/reviews/new" element={<New />} />
                <Route path="/profile" element={<Profile />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} /> // Redirect to login if not authenticated
            )}
          </Routes>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
