import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "AuthContext";
import { SyncLoader } from "react-spinners";
import Footer from "components/Footer";
import ForgetPasswordPopup from "components/ForgetPasswordPopup";
import Input from "scenes/signUp/Input";
import User from "User";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "./../../fonts/fonts.css";
import "./Login.css";
const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const { setIsAuthenticated } = useContext(AuthContext);
  const [popup, setPopup] = useState(false);
  const { t, i18n } = useTranslation();

  const [identifier, setIdentifier] = useState({
    value: "",
    isTouched: false,
    isValid: true,
  });

  const [password, setPassword] = useState({
    value: "",
    isTouched: false,
    isValid: true,
  });

  const [forgetPhone, setForgetPhone] = useState({
    value: "",
    isTouched: false,
    isValid: true,
  });

  function handleInputChange(inputName, e) {
    const value = e.target.value;

    switch (inputName) {
      case "forgetPhone":
        setForgetPhone({
          isTouched: true,
          value: value,
          isValid: true,
        });
        break;

      case "identifier":
        setIdentifier({
          isTouched: true,
          value: value,
          isValid: true,
        });
        break;

      case "password":
        setPassword({
          isTouched: true,
          value: value,
          isValid: true,
        });
        break;

      default:
        break;
    }
  }

  function handleResetInputs() {
    setPassword((prevPassword) => ({
      ...prevPassword,
      isTouched: true,
      value: "",
      isValid: true,
    }));
    setIdentifier((prevIdentifie) => ({
      ...prevIdentifie,
      isTouched: true,
      value: "",
      isValid: true,
    }));
  }

  async function handleSubmit() {
    if (!identifier.value || !password.value) {
      setError(t("fill_sign_up"));
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://dashboard.natindamiddleeast.com/api/login",
        {
          identifier: identifier.value,
          password: password.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const responseData = response.data;
      if (response.status === 200) {
        const user = new User(responseData.user);
        localStorage.setItem("user", JSON.stringify(user));
        setIsAuthenticated(true);

        if (location.state && location.state.fromQuiz) {
          navigate(location.state.result);
        } else {
          navigate("/");
          window.location.reload();
        }
      } else {
        setError(t("Login failed. Please try again."));

        handleResetInputs();
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.message === "Invalid"
      ) {
        setError(
          t("Incorrect email or phone number, or password. Please try again.")
        );
      } else {
        setError(t("Login failed. Please try again."));
      }
    } finally {
      setLoading(false);
    }
  }

  async function handleForgetPhoneSubmit() {
    if (!forgetPhone.value) {
      setError2("Please fill in the phone field.");
      return;
    }

    if (forgetPhone.value.length < 10) {
      setError2("Phone number must be at least 10 digits.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://dashboard.natindamiddleeast.com/api/forgetPassword",
        { phone: forgetPhone.value },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setPopup(false);
        navigate("/verification", {
          state: { fromResetPassword: true, phoneNumber: forgetPhone.value },
        });
      } else {
        setError2("Something went wrong. Please try again.");
      }
    } catch (error) {
      setError2("An error occurred. Please try again.");
      console.log("Error during forget password submission:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <main>
      {loading && (
        <div className="loader-overlay">
          <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
        </div>
      )}{" "}
      <div className="web-view-login">
        <div className="login-container">
          <div className="welcome-text">
            {i18n.language === "ar" ? (
              <>عميلنا العزيز، مرحباً بك مجدداً!</>
            ) : (
              <>
                Welcome Back,{" "}
                <span style={{ fontStyle: "italic" }}>Gorgeous!</span>
              </>
            )}
          </div>

          <Input
            name="identifier"
            type="text"
            onChange={(e) => handleInputChange("identifier", e)}
            isValid={identifier.isValid}
            isTouched={identifier.isTouched}
            placeholder={t("email_phone")}
            value={identifier.value}
            errorMsg={t("valid_email_phone")}
          />

          <Input
            name="password"
            type="password"
            onChange={(e) => handleInputChange("password", e)}
            isValid={password.isValid}
            isTouched={password.isTouched}
            placeholder={t("Password")}
            value={password.value}
            errorMsg={t("valid_password")}
          />

          <div onClick={() => setPopup(true)} className="forgot-password">
            {" "}
            {t("Forgot Password?")}{" "}
          </div>

          <button className="submit-login" type="submit" onClick={handleSubmit}>
            {t("signIn")}
          </button>

          {/* Display error message if there's an error */}
          {error && <div className="error-message-login">{error}</div>}

          <div className="no-account">
            {" "}
            {t("dont_account")}{" "}
            <span
              onClick={() => navigate("/sign-up")}
              className="underline"
              style={{ cursor: "pointer" }}
            >
              {t("dont_account_1")}
            </span>{" "}
          </div>
        </div>
      </div>
      <div className="mobile-view-login">
        <div className="login-container">
          <div className="welcome-text">
            {i18n.language === "ar" ? (
              <>عميلنا العزيز، مرحباً بك مجدداً!</>
            ) : (
              <>
                Welcome Back,{" "}
                <span style={{ fontStyle: "italic" }}>Gorgeous!</span>
              </>
            )}
          </div>

          <Input
            name="identifier"
            type="text"
            onChange={(e) => handleInputChange("identifier", e)}
            isValid={identifier.isValid}
            isTouched={identifier.isTouched}
            placeholder={t("email_phone")}
            value={identifier.value}
            errorMsg={t("valid_email_phone")}
          />

          <Input
            name="password"
            type="password"
            onChange={(e) => handleInputChange("password", e)}
            isValid={password.isValid}
            isTouched={password.isTouched}
            placeholder={t("Password")}
            value={password.value}
            errorMsg={t("valid_password")}
          />

          <div onClick={() => setPopup(true)} className="forgot-password">
            {" "}
            {t("Forgot Password?")}{" "}
          </div>

          <button className="submit-login" type="submit" onClick={handleSubmit}>
            {t("signIn")}
          </button>
          {/* Display error message if there's an error */}
          {error && <div className="error-message-login">{error}</div>}

          <div className="no-account">
            {" "}
            {t("dont_account")}{" "}
            <span
              onClick={() => navigate("/sign-up")}
              className="underline"
              style={{ cursor: "pointer" }}
            >
              {t("dont_account_1")}
            </span>{" "}
          </div>
        </div>
      </div>
      <ForgetPasswordPopup
        popup={popup}
        handleClosePopup={() => setPopup(false)}
        handleForgetPhoneInput={(e) => handleInputChange("forgetPhone", e)}
        forgetPhone={forgetPhone}
        handleForgetPhoneSubmit={handleForgetPhoneSubmit}
        error2={error2}
      />
      <Footer />
    </main>
  );
};

export default Login;
