// SingleProduct.js

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import vip from "./../../assets/vip.png";
import Footer from "components/Footer";
import { useNavigate } from "react-router-dom";
import User from "User";
import "./rewards.css";
import "./../../fonts/fonts.css";

import points100 from "./../../assets/100points.png";
import points20 from "./../../assets/points200.png";
import points30 from "./../../assets/points30.png";
import points40 from "./../../assets/points40.png";
import points50 from "./../../assets/points50.png";
import points1002 from "./../../assets/points1002.png";
import { useTranslation } from "react-i18next";

import vip_mob from "./../../assets/vip_mob.png";

const Rewards = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userData = User.fromLocalStorage();

    if (userData) {
      setUser(userData);
    }
  }, []);

  const navlogin = () => {
    navigate("/login");
  };

  const navsignup = () => {
    navigate("/sign-up");
  };

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const spanStyle = {
    marginTop: "40px",
    fontWeight: isArabic ? "600" : "500",
    fontSize: "40px",
  };

  return (
    <main>
      <div className="rewards-web">
        <div className="rewards-container">
          {/* rewards-first-container */}
          <div className="rewards-first-container">
            <div
              className={`rewards-first-container-left ${
                isArabic ? "arabic" : ""
              }`}
            >
              <span>{t("joinOurVipSquad")}</span>
              <div className="rewards-first-container-buttons">
                {user !== null ? (
                  <></>
                ) : (
                  <>
                    <button onClick={navlogin}> {t("signIn")} </button>
                    <button onClick={navsignup}> {t("signUp")} </button>
                  </>
                )}
              </div>
            </div>

            <div className="rewards-right-container">
              <img src={vip} alt="vip" />
            </div>
          </div>
          {/* rewards-second-container */}
          <div style={{ background: "#fff8f4", paddingTop: "100px" }}>
            <div className={`rewards-headline ${isArabic ? "arabic" : ""}`}>
              {t("howToJoinVip")}
            </div>
            <div
              className={`rewards-second-container ${isArabic ? "arabic" : ""}`}
            >
              <div>{t("createAccount")}</div>
              <div>
                {t("earnPoints")} <br />{" "}
                <span style={{ fontWeight: "300", fontSize: "24px" }}>
                  {t("sarPoints")}
                </span>{" "}
              </div>
              <div>{t("redeemPoints")}</div>
            </div>
          </div>

          <div
            style={{ marginTop: "50px" }}
            className={`rewards-headline ${isArabic ? "arabic" : ""}`}
          >
            {t("waysToEarnPoints")}{" "}
          </div>

          {/* rewards-third-container */}
          <div className="rewards-third-container">
            <div>
              {user !== null ? (
                <span style={spanStyle}>{t("completed")}</span>
              ) : (
                <>
                  <img src={points100} alt="points100" />
                  <span sstyle={spanStyle}>{t("100P")}</span>
                  {t("creatAnAccount")}
                </>
              )}
            </div>

            <div>
              <img src={points20} alt="points20" />
              <span style={spanStyle}>{t("20P")}</span>
              {t("writeReview")}
            </div>

            <div>
              <img src={points30} alt="points30" />
              <span style={spanStyle}>{t("30P")}</span>
              {t("writeReviewPhoto")}
            </div>

            <div>
              <img src={points40} alt="points40" />
              <span style={spanStyle}>{t("40P")}</span>
              {t("writeReviewVideo")}
            </div>

            <div>
              <img src={points50} alt="points50" />
              <span style={spanStyle}>{t("50P")}</span>
              {t("SignUpEmails")}
            </div>

            <div>
              <img src={points1002} alt="points40" />
              <span style={spanStyle}>{t("100P")}</span>
              {t("doSkinQuiz")}
            </div>
          </div>
          <Link to={"/terms-and-conditions"}>
            <div className={`rewards-terms ${isArabic ? "arabic" : ""}`}>
              {t("rewardsTermsAndConditions")}
            </div>
          </Link>
        </div>
      </div>
      <div className="rewards-mobile">
        <div className="rewards_vip_mob">
          <img src={vip_mob} alt="vip" />
        </div>

        <div className="join-vip-background">
          <div className={`rewards-headline-mob ${isArabic ? "arabic" : ""}`}>
            {t("joinOurVipSquad")}
          </div>
          <div className="rewards-first-container-buttons-mob">
            {user !== null ? (
              <></>
            ) : (
              <>
                <button onClick={navlogin}> {t("signIn")} </button>
                <button onClick={navsignup}> {t("signUp")} </button>
              </>
            )}
          </div>
        </div>

        <div
          style={{ marginBottom: "40px" }}
          className={`rewards-headline-mob ${isArabic ? "arabic" : ""}`}
        >
          {t("howToJoinVip")}
        </div>

        <div className="rewards-second-container-mob">
          <div> {t("createAccount")}</div>
          <div>
            {" "}
            {t("earnPoints")} <br />{" "}
            <span style={{ fontWeight: "300", fontSize: "18px" }}>
              {t("sarPoints")}
            </span>{" "}
          </div>
          <div> {t("redeemPoints")}</div>
        </div>

        <div
          style={{ marginBottom: "40px" }}
          className={`rewards-headline-mob ${isArabic ? "arabic" : ""}`}
        >
          {t("waysToEarnPoints")}
        </div>

        <div className="rewards-third-container-mob">
          <div>
            {user !== null ? (
              <span style={{ marginTop: "10px", fontWeight: "500" }}>
                {t("completed")}
              </span>
            ) : (
              <>
                <img src={points100} alt="points100" />
                <span style={{ marginTop: "10px", fontWeight: "500" }}>
                  {t("100P")}
                </span>
                {t("creatAnAccount")}
              </>
            )}
          </div>

          <div>
            <img src={points20} alt="points20" />
            <span style={{ marginTop: "10px", fontWeight: "500" }}>
              {t("20P")}
            </span>
            {t("writeReview")}
          </div>

          <div>
            <img src={points30} alt="points30" />
            <span style={{ marginTop: "10px", fontWeight: "500" }}>
              {t("30P")}
            </span>
            {t("writeReviewPhoto")}
          </div>

          <div>
            <img src={points40} alt="points40" />
            <span style={{ marginTop: "10px", fontWeight: "500" }}>
              {t("40P")}
            </span>
            {t("writeReviewVideo")}
          </div>

          <div>
            <img src={points50} alt="points50" />
            <span style={{ marginTop: "10px", fontWeight: "500" }}>
              {t("50P")}
            </span>
            {t("SignUpEmails")}
          </div>

          <div>
            <img src={points40} alt="points40" />
            <span style={{ marginTop: "10px", fontWeight: "500" }}>
              {t("100P")}
            </span>
            {t("doSkinQuiz")}
          </div>
        </div>

        <Link to={"/terms-and-conditions"}>
          <div className="rewards-terms">{t("rewardsTermsAndConditions")}</div>
        </Link>
      </div>
      <Footer />
    </main>
  );
};

export default Rewards;
