import "../../fonts/fonts.css";
import "../../styles/home.css";
import React, { useState, useEffect, useContext } from "react";
import { FaHeart } from "react-icons/fa";
import Footer from "components/Footer";
import AddToCart from "components/addToCart";
import AnimationVideo from "../../assets/Natinda-HD-PC.mp4";
import home_image_pc from "../../assets/home-image-pc3.jpeg";
import AnimationVideoMobile from "../../assets/NatindaReel.mp4";
import CardSwiper from "../../components/cardSwiper";
import QuizImage from "../../assets/quiz-home-pc-image.png";
import iconGroup from "../../assets/gold-home-icons.svg";
import iconGroupArabic from "../../assets/iconGroupAR.svg";
import QuizImage2 from "../../assets/0X5A4957 3.png";
import EarnPointsMobile from "../../assets/earn-points-mobile-2.png";
import { SyncLoader } from "react-spinners";
import { Link } from "react-router-dom";
import EarnPoints from "../../assets/Earn-point-image.svg";
import EarnPointsAR from "../../assets/EarnPointsHomePageAR.png";
import ProductImages from "ProductImages/productImages";
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";

import RewardsPopup from "components/RewardsPopup";
import { useLocation } from "react-router-dom";

import DiscountPopUp from "components/DiscountPopUp";

async function getBestSellerProducts() {
  try {
    const response = await fetch(
      "https://dashboard.natindamiddleeast.com/api/getBestSeller"
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching best seller products:", error.message);
    return [];
  }
}

export default function Home() {
  let guestSessionId = localStorage.getItem("guestId");

  const generateGuestSessionId = () => {
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    return `guest_${randomNumber}`;
  };

  if (guestSessionId === null) {
    const newGuestSessionId = generateGuestSessionId();
    localStorage.setItem("guestId", newGuestSessionId);
    guestSessionId = newGuestSessionId;
  }

  const [products, setProducts] = useState([]);
  const buttonColor = "#fddccd";
  const [isLoading, setLoading] = useState(false);
  const { addToCart } = useContext(CartContext);

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(true);

  useEffect(() => {
    if (location.state?.showPopup) {
      setPopupOpen(true);
    }
  }, [location.state]);

  const isArabic = i18n.language === "ar";

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await getBestSellerProducts();

      setProducts(data);
    };
    fetchProducts();
  }, []);

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleRewardsClosePopup = () => {
    setDiscountPopupOpen(false);
  };

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            height: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : (
        <div>
          <RewardsPopup
            isOpen={isPopupOpen}
            handleClosePopup={handleClosePopup}
          />

          <DiscountPopUp
            isOpen={isDiscountPopupOpen}
            handleClosePopup={handleRewardsClosePopup}
          />

          <div className="home-container mobile-hide">
            <div className={`headline-row-home ${isArabic ? "arabic" : ""}`}>
              {t("headline-row-home-1")}&nbsp;
              <span className={`home-text-italic ${isArabic ? "arabic" : ""}`}>
                {t("home-text-italic")}
              </span>
            </div>
            <div className="video-container">
              <video
                className="animation-Video"
                src={AnimationVideo}
                autoPlay
                loop
                muted
                playsInline
                preload="metadata"
              ></video>
            </div>

            <div className="best-Selling-Container">
              <div className={`best-Selling ${isArabic ? "arabic" : ""}`}>
                {t("best-Selling")}
              </div>
              <div className="products-list">
                {products.map((product, index) => (
                  <div key={index} className="single-product">
                    <Link
                      to={`/product/${encodeURIComponent(
                        product.name_en.replace(/\s+/g, "-").toLowerCase()
                      )}/${product.id}`}
                      className="single-product-image-container"
                    >
                      {product.status === "Sale" &&
                        product.discounted_price && (
                          <div className="sale-badge">{t("Sale")}</div>
                        )}

                      {product.status === "Sold out" && (
                        <div className="sale-badge"> {t("add_to_cart")}</div>
                      )}
                      <ProductImages id={product.id} />
                    </Link>

                    <div className="hearts-container">
                      {Array.from(
                        { length: Math.round(product.average_rating) },
                        (_, index) => (
                          <FaHeart
                            key={index}
                            className="hearts-container"
                            width={16}
                            height={14}
                          />
                        )
                      )}
                    </div>

                    <p
                      className={`product-name-home-pc ${
                        isArabic ? "arabic" : ""
                      }`}
                    >
                      <Link
                        to={`/product/${encodeURIComponent(
                          product.name_en.replace(/\s+/g, "-").toLowerCase()
                        )}/${product.id}`}
                      >
                        {isArabic ? product.name_ar : product.name_en}
                      </Link>
                    </p>
                    <AddToCart
                      buttonColor={buttonColor}
                      product={product}
                      setLoading={setLoading}
                      onClick={(event) => handleAddToCart(product.id, event)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <Link
              to={"/products"}
              className={`ShopAll-Button ${isArabic ? "arabic" : ""}`}
            >
              {t("ShopAll-home-Button")}
            </Link>

            <div className={`headline-row-home ${isArabic ? "arabic" : ""}`}>
              {t("headline-row-home-2")}&nbsp;
              <span
                className={`headline-row-home-italic ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("headline-row-home-italic")}
              </span>
            </div>
            <img
              className="animation-Image"
              src={home_image_pc}
              alt="Animation"
            />

            {/* <img src={iconGroup} className="iconGroupPC" alt="icons" /> */}

            {isArabic ? (
              <img src={iconGroupArabic} className="iconGroupPC" alt="icons" />
            ) : (
              <img src={iconGroup} className="iconGroupPC" alt="icons" />
            )}

            <div className="quiz-background">
              <div className={`quizFrame-text ${isArabic ? "arabic" : ""}`}>
                <div className={`quiz-text ${isArabic ? "arabic" : ""}`}>
                  {t("quiz-home-text")}
                </div>
                <Link
                  to={"/quiz"}
                  className={`take-quiz ${isArabic ? "arabic" : ""}`}
                >
                  {t("quiz-home-button")}
                </Link>
              </div>
              <img className="quiz-image" src={QuizImage} alt="Quiz" />
            </div>

            <div className="earn-point-cont">
              <div
                className={`earn-points-text-cont ${isArabic ? "arabic" : ""}`}
              >
                <div
                  className={`earn-points-title ${isArabic ? "arabic" : ""}`}
                >
                  {t("earn-points-home-title")}
                </div>
                <div
                  className={`earn-points-subtitle ${isArabic ? "arabic" : ""}`}
                >
                  {t("earn-points-home-subtitle")}
                </div>
                <div className={`earn-points-text ${isArabic ? "arabic" : ""}`}>
                  {t("earn-points-home-text")}
                </div>
                <Link to={"/rewards"}>
                  <div
                    className={`earn-now-button ${isArabic ? "arabic" : ""}`}
                  >
                    {t("earn-now-home-button")}
                  </div>
                </Link>
              </div>
              {isArabic ? (
                <img
                  src={EarnPointsAR}
                  alt="earnpoints"
                  style={{ width: "100%" }}
                />
              ) : (
                <img
                  src={EarnPoints}
                  alt="earnpoints"
                  style={{ width: "100%" }}
                />
              )}
            </div>
          </div>

          <div className="mobile-home-container">
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: `
                    <video
                      loop
                      muted
                      autoplay
                      playsinline
                      src="${AnimationVideoMobile}"
                      
                    />,
                  `,
              }}
            ></div>

            <div className={`home-text1 ${isArabic ? "arabic" : ""}`}>
              {t("headline-row-home-1")}&nbsp;
              <span className={`home-text-italic ${isArabic ? "arabic" : ""}`}>
                {t("home-text-italic")}
              </span>
            </div>

            <div
              className={`best-selling-mobile-text ${isArabic ? "arabic" : ""}`}
            >
              {t("best-Selling")}
            </div>

            <CardSwiper products={products} />

            <Link
              to={"/products"}
              className={`ShopAll-Button ${isArabic ? "arabic" : ""}`}
            >
              {t("ShopAll-home-Button")}
            </Link>
            <div className={`home-text2 ${isArabic ? "arabic" : ""}`}>
              {t("headline-row-home-2")}&nbsp;
              <span className={`home-text2-italic ${isArabic ? "arabic" : ""}`}>
                {t("headline-row-home-italic")}
              </span>
            </div>
            <img
              src={home_image_pc}
              className="home-image2"
              alt="animation mobile"
            />
            {isArabic ? (
              <img src={iconGroupArabic} className="iconGroup" alt="icons" />
            ) : (
              <img src={iconGroup} className="iconGroup" alt="icons" />
            )}

            <div className="quiz-container-mobile">
              <div
                className={`quizFrame-text-mobile ${isArabic ? "arabic" : ""}`}
              >
                <div className={`quiz-text-mobile ${isArabic ? "arabic" : ""}`}>
                  {t("quiz-home-text")}
                </div>
                <Link
                  to={"/quiz"}
                  className={`quiz-button-mobile ${isArabic ? "arabic" : ""}`}
                >
                  {t("quiz-home-button")}
                </Link>
              </div>
              <img src={QuizImage2} alt="Quiz" className="quiz-image-mobile" />
            </div>

            <div className="earn-point-cont">
              <div
                className={`earn-points-text-cont ${isArabic ? "arabic" : ""}`}
              >
                <div
                  className={`earn-points-title ${isArabic ? "arabic" : ""}`}
                >
                  {t("earn-points-home-title")}
                </div>
                <div
                  className={`earn-points-subtitle ${isArabic ? "arabic" : ""}`}
                >
                  {t("earn-points-home-subtitle-mobile")}
                </div>
                <div className={`earn-points-text ${isArabic ? "arabic" : ""}`}>
                  {t("earn-points-home-text-mobile")}
                </div>
                <Link to={"/rewards"}>
                  <div
                    className={`earn-now-button ${isArabic ? "arabic" : ""}`}
                  >
                    {t("earn-now-home-button")}
                  </div>
                </Link>
              </div>
              {isArabic ? (
                <img
                  src={EarnPointsMobile} // or EarnPointsAR if you want a different image for Arabic
                  alt="earnpoints"
                  style={{
                    width: "100%",
                    transform: "scaleX(-1)", // Flip the image horizontally
                  }}
                />
              ) : (
                <img
                  src={EarnPointsMobile}
                  alt="earnpoints"
                  style={{ width: "100%" }} // No flipping, keep it as is
                />
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </main>
  );
}
