import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import WriteReview from "components/WriteReview";
import Footer from "components/Footer";
import { SyncLoader } from "react-spinners";

const New = () => {
  const location = useLocation();
  const {
    state: { productTheme, id: productId },
  } = location;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [productId, productTheme]);

  if (loading) {
    return (
      <div className="global-full-page">
        <div className="loader-overlay">
          <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
        </div>
      </div>
    );
  }

  return (
    <main style={{ paddingTop: "115px" }}>
      <WriteReview ProductColor={productTheme} ProductId={productId} />
      <Footer productTheme={productTheme} />
    </main>
  );
};

export default New;
