import React, { useState, useEffect, useContext } from "react";
import "../../styles/orderSuccess.css";
import { FaCheckCircle } from "react-icons/fa";
import { useParams, Link, useNavigate } from 'react-router-dom';
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";
import Footer from "components/Footer";


const OrderSuccess = () => {
  const { id } = useParams();
  const { fetchCartCount } = useContext(CartContext);
  const { t } = useTranslation();
  const [order, setOrder] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchCartCount();
    handleGetOrder();
  }, []);

  const handleGetOrder = async () => {
    try {
      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/fetchOrderDetailsById?order_id=${id}`, // Include order_id as a query parameter
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) { // Check if the response is successful
        throw new Error('Order not found');
      }

      const responseData = await response.json();
      setOrder(responseData);
      console.log(responseData);
    } catch (error) {
      console.error("Error while viewing cart", error);
      navigate("/login"); // Redirect to the login page on error
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Get day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Add suffix to the day
    const suffix = (day % 10 === 1 && day !== 11) ? 'st' :
                   (day % 10 === 2 && day !== 12) ? 'nd' :
                   (day % 10 === 3 && day !== 13) ? 'rd' : 'th';

    // Return the formatted date in the form of "31st October 2024"
    return `${day}${suffix} ${month} ${year}`;
  };

  if (!order.order) {
    return <div>Loading...</div>; // Return a loading state while the order is being fetched
  }

  return (
    <>
      <div className="orderConfirmation-container">
        <div className="orderConfirmation-Left-Side">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FaCheckCircle className="orderConfirmation-corect-sign" />
          </div>
          <div className="order-Confirmed-title">
            {t("Order_Confirmed_Title")}
          </div>
          <div className="order-Confirmed-text-body">
            {t("Order_Confirmed_Text_Body")}
          </div>
          <Link to="/products">
            <div className="continue-shopping-button-OC">
              {t("Continue_Shopping_Button")}
            </div>
          </Link>
        </div>
        {/* <div className="orderConfirmation-Right-Side">
          <div>Order Summary</div>
        </div> */}
      </div>

      <div className="orderConfirmation-mobile">
        <div className="orderConfirmation-mobile-container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FaCheckCircle className="orderConfirmation-corect-sign" />
          </div>
          <div className="order-Confirmed-title">
            {t("Order_Confirmed_Title")}
          </div>
          <div className="order-Confirmed-text-body">
            {t("Order_Confirmed_Text_Body")}
          </div>
          <Link to="/products">
            <div className="continue-shopping-button-OC">
              {t("Continue_Shopping_Button")}
            </div>
          </Link>
        </div>
        <div className="orderSummery-Mobile">
          <div className="orderConfirmation-Title">
            <div>{t("Order Confirmation#")}</div>
            <div>{order.order.id}</div>
          </div>
          <div className="orderConfirmation-details">
            <div>{t("Order Amount")}</div>
            <div>{order.order.subtotal} {t("SAR")}</div>
          </div>
          <div className="orderConfirmation-details">
            <div>{t("Shipping Fees")}</div>
            <div>{order.order.shipping_fees} {t("SAR")}</div>
          </div>
          {order.order.points != null && order.order.promocode_id==null&& (
            <div className="orderConfirmation-details">
              <div>{t("Discount")}</div>
              <div>- {order.order.points_price} {t("SAR")}</div>
            </div>
          )}
          {order.order.promocode_id != null && order.order.points==null&& (
            <div className="orderConfirmation-details">
              <div>{t("Discount")}</div>
              <div>- {order.order.promocode_price} {t("SAR")}</div>
            </div>
          )}

          <div className="orderConfirmation-totalPrice">
            <div>{t("Total Fees")}</div>
            <div>{order.order.total_price} {t("SAR")}</div>
          </div>

          <div className="orderConfirmation-Delivery">
            <div>{t("Delivery Address")}</div>
            <div className="orderConfirmation-addressDate">
              <div className="orderConfirmation-addressDateWidth">
                {order.order.shippingaddress.appartment}, {order.order.shippingaddress.address}, {order.order.shippingaddress.city}, {order.order.shippingaddress.country}
              </div>
              <div>{formatDate(order.order.updated_at)}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderSuccess;
