import React, { useState, useEffect } from "react";
import Footer from "components/Footer";
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import ErrorImage from "./../../assets/error.png";
import { useParams } from "react-router-dom";
import "./track-order.css";

const TrackOrder = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const response = await fetch(
          "https://dashboard.natindamiddleeast.com/api/trackOrder",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ order_id: id }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch order status: ${response.statusText}`
          );
        }

        const responseData = await response.json();
        setOrderStatus(responseData);
      } catch (error) {
        console.error("Error fetching order status:", error);
        setErrorMsg(`Error fetching order status: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderStatus();
  }, [id]);

  if (loading) {
    return (
      <main className="global-full-page">
        <div className="loader-overlay">
          <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
        </div>
      </main>
    );
  }

  if (errorMsg) {
    return (
      <main className="error-container">
        <img src={ErrorImage} alt="error" />
        <div className="error-text">{t("unexpected_error")}</div>
        <Footer />
      </main>
    );
  }

  return (
    <main>
      <div className="track-order">
        <div className="tracking-container">
          <div className="tracking-steps">
            {orderStatus ? (
              <>
                <div className="step">
                  <div className="step-circle completed">✓</div>
                  <div className="step-label">
                    <div>Order Received</div>
                    <div className="step-date">Est. {orderStatus.date}</div>
                  </div>
                </div>
                <div className="step">
                  <div className="step-circle completed">✓</div>
                  <div className="step-label">
                    <div>Order Processed</div>
                    <div className="step-date">
                      Est. {orderStatus.processedDate}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="step-circle completed">✓</div>
                  <div className="step-label">
                    <div>Order Shipped</div>
                    <div className="step-date">
                      Est. {orderStatus.shippedDate}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="step-circle">
                    {orderStatus.outForDelivery ? "✓" : "4"}
                  </div>
                  <div className="step-label">
                    <div>Out for Delivery</div>
                    <div className="step-date">
                      Est. {orderStatus.outForDeliveryDate}
                    </div>
                  </div>
                </div>
                <div className="step">
                  <div className="step-circle">
                    {orderStatus.delivered ? "✓" : "D"}
                  </div>
                  <div className="step-label">
                    <div>Delivered</div>
                    <div className="step-date">
                      Est. {orderStatus.deliveredDate}
                    </div>
                  </div>
                </div>
                <div className="step-line" />
              </>
            ) : (
              <div>{t("no_order_status")}</div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default TrackOrder;
