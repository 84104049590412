import React, { useState, useEffect } from "react";
import "./about_us.css";
import "../../fonts/fonts.css";
import Footer from "components/Footer";
import { Link } from "react-router-dom";
import BackgroundImage from "./../../assets/about_bg.png";
import BackgroundImage1 from "./../../assets/about_bg_1.png";
import BackgroundImage2 from "./../../assets/about_bg_2.png";
import BackgroundMobImage from "./../../assets/about_bg_mob.png";
import BackgroundMobImage1 from "./../../assets/about_bg_mob_1.png";
import BackgroundMobImage2 from "./../../assets/about_bg_mob_2.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const [activeSection, setActiveSection] = useState("value");

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  useEffect(() => {}, []);

  return (
    <main>
      <>
        <div className="about-us-web-view">
          <div className="about-us-container">
            <div className="about-us-background">
              {/* value active session */}
              {activeSection === "value" && (
                <img src={BackgroundImage} alt="BackgroundImage" />
              )}
              {/* promise active session */}
              {activeSection === "promise" && (
                <img src={BackgroundImage1} alt="BackgroundImage1" />
              )}
              {/* story active session */}
              {activeSection === "story" && (
                <img src={BackgroundImage2} alt="BackgroundImage2" />
              )}
            </div>
          </div>

          <div className="about_us_row">
            <div className="about_us_first_column">
              <Link onClick={() => setActiveSection("value")}>
                <div
                  className={`about_us_box ${isArabic ? "arabic" : ""}`}
                  style={{
                    backgroundColor:
                      activeSection === "value" ? "#FCDBCC" : "transparent",
                    border:
                      activeSection !== "value" ? "1px solid #E3A689" : "none",
                  }}
                >
                  {t("aboutUs-Brand-Values")}
                </div>
              </Link>

              <Link onClick={() => setActiveSection("promise")}>
                <div
                  className={`about_us_box ${isArabic ? "arabic" : ""}`}
                  style={{
                    backgroundColor:
                      activeSection === "promise" ? "#FCDBCC" : "transparent",
                    border:
                      activeSection !== "promise"
                        ? "1px solid #E3A689"
                        : "none",
                  }}
                >
                  {t("aboutUs-Brand-Promise")}
                </div>
              </Link>

              <Link onClick={() => setActiveSection("story")}>
                <div
                  className={`about_us_box ${isArabic ? "arabic" : ""}`}
                  style={{
                    backgroundColor:
                      activeSection === "story" ? "#FCDBCC" : "transparent",
                    border:
                      activeSection !== "story" ? "1px solid #E3A689" : "none",
                  }}
                >
                  {t("aboutUs-Brand-Story")}
                </div>
              </Link>
            </div>

            {/* value active session */}
            {activeSection === "value" && (
              <div className={`brand-value ${isArabic ? "arabic" : ""}`}>
                <div
                  className={`brand-value-title ${isArabic ? "arabic" : ""}`}
                >
                  {t("aboutUs-Brand-Values-What-We")}{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {t("aboutUs-Brand-Values-Believe-in")}
                  </span>
                </div>
                <div className="brand-value-head">
                  {t("aboutUs-Brand-Values-Header1")}
                </div>
                <div className="brand-value-text">
                  {t("aboutUs-Brand-Values-text1A")}
                  <br />
                  {t("aboutUs-Brand-Values-text1B")}
                </div>

                <div className="brand-value-head">
                  {t("aboutUs-Brand-Values-Header2")}
                </div>
                <div className="brand-value-text">
                  {t("aboutUs-Brand-Values-text2")}
                </div>

                <div className="brand-value-head">
                  {t("aboutUs-Brand-Values-Header3")}
                </div>
                <div className="brand-value-text">
                  {t("aboutUs-Brand-Values-text3")}
                </div>

                <div className="brand-value-head">
                  {t("aboutUs-Brand-Values-Header4")}
                </div>
                <div className="brand-value-text">
                  {t("aboutUs-Brand-Values-text4")}
                </div>

                <div className="brand-value-head">
                  {t("aboutUs-Brand-Values-Header5")}
                </div>

                <div className="brand-value-text">
                  {t("aboutUs-Brand-Values-text5")}
                </div>
              </div>
            )}
            {/* promise active session */}
            {activeSection === "promise" && (
              <div className={`promise ${isArabic ? "arabic" : ""}`}>
                <div style={{ marginBottom: "15px" }}>
                  {t("aboutUs-Brand-Promise-Your-Reflection")}{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {t("aboutUs-Brand-Promise-Redefined")}
                  </span>{" "}
                </div>
                <div className={`promise-text ${isArabic ? "arabic" : ""}`}>
                  {t("aboutUs-Brand-Promise-text")}
                </div>
              </div>
            )}
            {/* story active session */}
            {activeSection === "story" && (
              <div className={`promise ${isArabic ? "arabic" : ""}`}>
                <div style={{ marginBottom: "15px" }}>
                  {t("aboutUs-Brand-Story-Women")}{" "}
                  <span style={{ fontStyle: "italic" }}>
                    {t("aboutUs-Brand-Story-Solidarity")}
                  </span>
                </div>
                <div className={`promise-text ${isArabic ? "arabic" : ""}`}>
                  {t("aboutUs-Brand-Story-text")}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="about-us-mobile-view">
          {/* Similar mapping for mobile view if different layout/content is required */}
          <div className="about-us-background">
            {/* value active session */}
            {activeSection === "value" && (
              <img src={BackgroundMobImage} alt="BackgroundImage" />
            )}
            {/* promise active session */}
            {activeSection === "promise" && (
              <img src={BackgroundMobImage1} alt="BackgroundImage1" />
            )}
            {/* story active session */}
            {activeSection === "story" && (
              <img src={BackgroundMobImage2} alt="BackgroundImage2" />
            )}
          </div>

          <div className="about_us_row_mob">
            <Link onClick={() => setActiveSection("value")}>
              <div
                className="about_us_box_mob"
                style={{
                  backgroundColor:
                    activeSection === "value" ? "#FCDBCC" : "transparent",
                  border:
                    activeSection !== "value" ? "1px solid #E3A689" : "none",
                }}
              >
                {t("aboutUs-Brand-Values")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("promise")}>
              <div
                className="about_us_box_mob"
                style={{
                  backgroundColor:
                    activeSection === "promise" ? "#FCDBCC" : "transparent",
                  border:
                    activeSection !== "promise" ? "1px solid #E3A689" : "none",
                }}
              >
                {t("aboutUs-Brand-Promise")}
              </div>
            </Link>

            <Link onClick={() => setActiveSection("story")}>
              <div
                className="about_us_box_mob"
                style={{
                  backgroundColor:
                    activeSection === "story" ? "#FCDBCC" : "transparent",
                  border:
                    activeSection !== "story" ? "1px solid #E3A689" : "none",
                }}
              >
                {t("aboutUs-Brand-Story")}
              </div>
            </Link>
          </div>

          {/* value active session */}
          {activeSection === "value" && (
            <div className="brand-value-mob">
              <div
                className={`brand-value-title-mob ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-What-We")}{" "}
                <span style={{ fontStyle: "italic" }}>
                  {t("aboutUs-Brand-Values-Believe-in")}
                </span>
              </div>
              <div
                className={`brand-value-mob-head ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-Header1")}
              </div>
              <div
                className={`brand-value-mob-text ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-text1A")}
                <br />
                {t("aboutUs-Brand-Values-text1B")}
              </div>

              <div
                className={`brand-value-mob-head ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-Header2")}
              </div>
              <div
                className={`brand-value-mob-text ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-text2")}
              </div>

              <div
                className={`brand-value-mob-head ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-Header3")}
              </div>
              <div
                className={`brand-value-mob-text ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-text3")}
              </div>

              <div
                className={`brand-value-mob-head ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-Header4")}
              </div>
              <div
                className={`brand-value-mob-text ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-text4")}
              </div>

              <div
                className={`brand-value-mob-head ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-Header5")}
              </div>
              <div
                className={`brand-value-mob-text ${isArabic ? "arabic" : ""}`}
              >
                {t("aboutUs-Brand-Values-text5")}
              </div>
            </div>
          )}
          {/* promise active session */}
          {activeSection === "promise" && (
            <div className={`promise-mob ${isArabic ? "arabic" : ""}`}>
              <div style={{ marginBottom: "15px" }}>
                {t("aboutUs-Brand-Promise-Your-Reflection")}{" "}
                <span style={{ fontStyle: "italic" }}>
                  {t("aboutUs-Brand-Promise-Redefined")}
                </span>{" "}
              </div>
              <div className={`promise-mob-text ${isArabic ? "arabic" : ""}`}>
                {t("aboutUs-Brand-Promise-text")}
              </div>
            </div>
          )}
          {/* story active session */}
          {activeSection === "story" && (
            <div className={`promise-mob ${isArabic ? "arabic" : ""}`}>
              <div style={{ marginBottom: "15px" }}>
                {t("aboutUs-Brand-Story-Women")}{" "}
                <span style={{ fontStyle: "italic" }}>
                  {t("aboutUs-Brand-Story-Solidarity")}
                </span>
              </div>
              <div className={`promise-mob-text ${isArabic ? "arabic" : ""}`}>
                {t("aboutUs-Brand-Story-text")}
              </div>
            </div>
          )}
        </div>
      </>
      <Footer />
    </main>
  );
};

export default AboutUs;
