import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import "../../styles/payment.css";
import RightArrow from "../../assets/chevron-right-md-faded.svg";
import closeSign from "../../assets/X-close.svg";
import User from "User";
import { SyncLoader, ClipLoader } from "react-spinners";
import cardProviders from "../../assets/creditCardOptions.svg";
import Footer from "../../components/Footer";
import ReactSwitch from "react-switch";
import Button from "../signUp/Button";
import { useNavigate, Link } from "react-router-dom";
import CounterBox from "components/CounterBox";
import BlackLine from "../../assets/Line 3.svg";
import CartContext from "CartContext";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Payment = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const navigate = useNavigate();
  const { id } = useParams();
  const [responseDatas, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [errorPromo, setErrorPromo] = useState(false);
  const [promoCode, setPromoCode] = useState({
    value: "",
    isTouched: false,
});
  const [isUser, setIsUser] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [isPromoOn, setIsPromoOn] = useState(false);
  const [toogleDisableFlag, settoogleDisableFlag] = useState(false);
  const [errorPromoPoints, setErrorPromoPoints] = useState(false);
  const [errorPoints, setErrorPoints] = useState(false);
  const [cartEmpty, setCartEmpty] = useState(false);
  const [shipFree, setShipFree] = useState(false);
  const [products, setProducts] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [cart, setCart] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [button, setButton] = useState(`${t("PAY NOW")}`);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const { fetchCartCount } = useContext(CartContext);
  const [isKSA, setIsKsa] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        await getShippmentInfo();
        await handleGetCart();
        const user = User.fromLocalStorage();
        if (user) {
          setIsUser(true);
        }
      } catch (error) {
        setError(true);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [isSwitchOn, isPromoOn]);

  async function handleSubmit() {
    try {
      setLoading(true);
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 30000,
      };

      if (selectedOption === "cash") {
        const response = await axios.post(
          "https://dashboard.natindamiddleeast.com/api/createOrder",
          {
            cart_id: cart.cart_id,
          },
          axiosConfig,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          setLoading(false);
          navigate(`/order/orderConfirmation/${response.data.order_id}`);
        } else {
          setLoading(false);
          console.error("Order creation failed with status:", response.status);
        }
      } else if (selectedOption === "card") {
        const response = await axios.post(
          "https://dashboard.natindamiddleeast.com/api/paymentrequest",
          {
            cart_id: cart.cart_id,
          },
          axiosConfig,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status >= 200 && response.status < 300) {
          const redirectUrl = response.data.redirect_url;
          setLoading(false);
          window.location.href = redirectUrl;
        } else {
          setLoading(false);
          console.error("Payment request failed with status:", response.status);
        }
      }
    } catch (error) {
      setLoading(false);

      if (error.response && error.response.status === 400 && error.response.data.error === "Prices Changed.") {
       
        window.location.reload(); // Reload the page to refresh cart details
    }

      console.error("Error while handling payment request:", error);
      console.log("error", error);
    }
  }

  const handleGetCart = async () => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/viewCart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      if (responseData.message === "Cart is empty") {
        navigate(`/cart`);
        setCartEmpty(true);
      } else {
        setSubtotal(responseData.sub_total);
        setProducts(responseData.cart);
        setCartEmpty(false);
        setCart(responseData);

        if (responseData.sub_total >= 300 && selectedOption === "card") {
          setShipFree(true);
        } else {
          setShipFree(false);
        }

        if (responseData.points) {
          setIsSwitchOn(true);
          setIsPromoOn(false);
          setDiscount(responseData.points_price);
        } else if (responseData.promocode) {
          setIsSwitchOn(false);
          setIsPromoOn(true);

          setDiscount(responseData.promocode_price);
          setPromoCode({
            value: responseData.promocode_details.code,
          });
          if (User.fromLocalStorage()) {
            toogleDisableFlag(true);
          }
        } else {
          setIsSwitchOn(false);
          setIsPromoOn(false);
        }
      }
    } catch (error) {
      console.error("Error while viewing cart", error);
    }
  };
  const getShippmentInfo = async () => {
    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    try {
      const response = await fetch(
        "https://dashboard.natindamiddleeast.com/api/getShipmentInfo",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      setResponseData(responseData); 
      console.log(responseData.country);
      if (responseData.error === "You dont have shipmet info.") {
        navigate(`/cart`);
        setCartEmpty(true);
      }
      else if(responseData.country==="SA"){
        setIsKsa(true);
      }else {
        handleOptionChange("card");  // Set default to "card" if not in KSA
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching data:", error);
    }
  };

  const handleOptionChange = async (value) => {
    if (!loadingOptions) {
      setLoadingOptions(true);

      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

     // If the function is called with an event, use the event target value, otherwise use the value passed
    const selectedValue = typeof value === "string" ? value : value.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === "cash") {
      setButton(`${t("Complete Order")}`);
    } else if (selectedValue === "card") {
      setButton(`${t("PAY NOW")}`);
    }

      try {
        const response = await fetch(
          "https://dashboard.natindamiddleeast.com/api/editShipmentCost",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },

            body: JSON.stringify({ option: selectedValue }),
          }
        );

        handleGetCart();
      } catch (error) {
        setError(true);
        console.error("Error fetching data:", error);
      } finally {
        setLoadingOptions(false);
      }
    }
  };
  const handlePromoCodeChange = (event) => {
    setErrorPromo(false);
    setErrorPromoPoints(false);
    const newValue = event.target.value;
    setPromoCode({
      ...promoCode,
      value: newValue,
    });
  };

  const generateGuestSessionId = () => {
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    return `guest_${randomNumber}`;
  };
  async function handleSubmitPromocode() {
    setErrorPromo(false);
    if (!isSwitchOn && !isPromoOn) {
      setLoadingPromo(true);
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      try {
        const response = await fetch(
          `https://dashboard.natindamiddleeast.com/api/applyPromoCode?promocode=${promoCode.value}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();
        if (responseData.message === "Promocode added Successfully") {
          settoogleDisableFlag(true);
          setIsPromoOn(true);
        } else {
          setErrorPromo(true);
          setIsSwitchOn(false);
        }
      } catch (error) {
        setError(true);
        console.error("Error fetching data:", error);
      } finally {
        setLoadingPromo(false);
      }
    }
  }
  async function handleRemovePromo() {
    setLoadingPromo(true);
    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    try {
      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/removePromoCode`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      if (responseData.message === "Promocode removed Successfully") {
        settoogleDisableFlag(false);
        setIsPromoOn(false);
      } else {
        setErrorPromo(false);
        setIsSwitchOn(false);
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoadingPromo(false);
    }
  }
  async function switch_onChange_handle() {
    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    }
    setIsSwitchOn(!isSwitchOn);
    setErrorPoints(false);
    if (!isSwitchOn) {
      try {
        const response = await fetch(
          `https://dashboard.natindamiddleeast.com/api/applyPoints`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        if (responseData.message === "Points added Successfully") {
          setIsPromoOn(false);
          setIsSwitchOn(true);
        } else if (responseData.error === "You Dont have points yet") {
          setErrorPoints(true);
          setIsSwitchOn(false);
        } else if (responseData.error === "You already using your points") {
          setIsSwitchOn(true);
        }
      } catch (error) {
        setError(true);
        console.error("Error fetching data:", error);
      }
    } else {
      try {
        const response = await fetch(
          `https://dashboard.natindamiddleeast.com/api/removePoints`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        if (responseData.message === "Points removed Successfully") {
          setIsPromoOn(false);
        }
      } catch (error) {
        setError(true);
        console.error("Error fetching data:", error);
      }
    }
  }
  const editProductInCart = async (productId, quantity) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }
      const option = {
        option: selectedOption,
      };

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/editCart?product_id=${productId}&quantities=${quantity}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(option),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        await handleGetCart();
      } else {
        console.error("Error updating product quantity:", response.statusText);
      }

      return responseData; // Return the response data
    } catch (error) {
      console.error("Error updating product quantity:", error);
    } finally {
      setLoading(false);
    }
  };
  const removeProductFromCart = async (productId) => {
    try {
      setLoading(true);
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }
      const option = {
        option: selectedOption,
      };

      const response = await fetch(
        `https://dashboard.natindamiddleeast.com/api/removeProductFromCart?product_id=${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(option),
        }
      );
      if (response.ok) {
        handleGetCart();
        fetchCartCount();
      }
    } catch (error) {
      console.error("Error while removing product from cart", error);
    } finally {
      setLoading(false);
    }
  };

  const formIsValid = selectedOption !== "";

  // Utility function to format numbers based on language
  const formatNumber = (number, language) => {
    return new Intl.NumberFormat(language === "ar" ? "ar-EG" : "en-US").format(
      number
    );
  };

  return (
    <main>
      <div className="mobile-payment-page">
        <div className="row-drop3">
          <div className="red3"></div>
          <div className="light-gray3"></div>
        </div>

        <div className="ordermap3">
          <div className="Information3">{t("1. Information")}</div>
          <img
            src={RightArrow}
            alt="right arrow"
            width={16}
            height={16}
            className={i18n.language === "ar" ? "mirror" : ""}
          />
          <div className="shipping3">{t("2. Shipping")}</div>
          <img
            src={RightArrow}
            alt="right arrow"
            width={16}
            height={16}
            className={i18n.language === "ar" ? "mirror" : ""}
          />
          <div className="payment3">{t("3. Payment")}</div>
        </div>

        <div className="mini">
          {loading ? (
            <div className="loader-overlay">
              <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
            </div>
          ) : (
            <div className="payment-page-container">
              <div className="payment-page-container-2">
                <Link to={`/cart/${id}`} className="edit-shipping-page">
                  {t("edit")}
                </Link>
                <div className="first-box-payment-page">
                  <div className="contact-payment-page">
                    <div className="first-box-title-payment-page">
                      {t("contact")}
                    </div>
                    <div className="first-box-email-payment-page">
                      {responseDatas?.email}
                    </div>
                  </div>
                  <div className="black-line-payment-page"></div>
                  <div className="ship-to-payment-page">
                    <div className="first-box-title-payment-page">
                      {t("shipTo")}
                    </div>
                    <div className="first-box-email-payment-page">
                      {responseDatas?.appartment}, {responseDatas?.address},{" "}
                      {responseDatas?.city}, {responseDatas?.country}
                    </div>
                  </div>
                  <div className="black-line-payment-page"></div>
                  <div className="shipping-method-payment-page">
                    <div className="first-box-title-payment-page">
                      {" "}
                      {t("shippingMethod")}
                    </div>
                    <div className="first-box-email-payment-page">
                      {" "}
                      {t("standardShippment")}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="promocode-title-payment-page">
                    {t("promoCode")}
                  </div>

                  <div className="promocode-container-payment-page">
                    <div className="promocode-text-box-cont-payment-page">
                      <input
                        disabled={isSwitchOn || isPromoOn}
                        className="promocode-text-box-payment-page"
                        name="promocode"
                        type="text"
                        placeholder={t("promoCodeOrGift")}
                        value={promoCode.value}
                        onChange={handlePromoCodeChange}
                      />

                      {isPromoOn && (
                        <img
                          src={closeSign}
                          onClick={handleRemovePromo}
                          alt="Close Sign"
                        />
                      )}
                    </div>

                    <div
                      className="promocode-button-payment-page"
                      onClick={handleSubmitPromocode}
                    >
                      {loadingPromo ? (
                        <div className="loader-overlay-payment-page">
                          <ClipLoader
                            className="ClipLoader"
                            color="rgb(255, 255, 255)"
                          />
                        </div>
                      ) : (
                        <img
                          src={RightArrow}
                          alt="right arrow"
                          width={16}
                          height={16}
                          className={i18n.language === "ar" ? "mirror" : ""}
                        />
                      )}
                    </div>
                  </div>
                  {errorPromoPoints && (
                    <div className="promocode-error-message">
                      {t("Can't work with the points")}
                    </div>
                  )}
                  {errorPromo && (
                    <div className="promocode-error-message">
                      {t("OOPS! Coupon invalid")}
                    </div>
                  )}
                </div>

                {isUser && (
                  <div className="switch-toogle-container">
                    <div className="switch-toogle">
                      <ReactSwitch
                        onChange={switch_onChange_handle}
                        checked={isSwitchOn}
                        disabled={!isSwitchOn && toogleDisableFlag}
                        offColor={"#f0f0f0"}
                        onColor={"#fff4f0"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        onHandleColor={"#fddccd"}
                        offHandleColor={"#fddccd"}
                        width={30}
                        height={15}
                      />
                      <div className="switch-toogle-text">
                        {t("Redeem Points")}
                      </div>
                    </div>
                    {errorPoints && (
                      <div className="promocode-error-message">
                        {t("You have 0 points")}
                      </div>
                    )}
                  </div>
                )}

                <div className="payment-title-payment-page">{t("payment")}</div>
                

                <div className="second-box-payment-page"
                      style={!isKSA ? { justifyContent: "center" } : {}}>
                  
                {isKSA && (
                        <>
                  <div
                    className={`container-payment-page-img ${
                      isArabic ? "rtl" : ""
                    }`}
                  >
                    <label
                      className={`container-payment-page ${
                        isArabic ? "rtl" : ""
                      }`}
                    >
                      {t("COD")}
                      <input
                        type="radio"
                        checked={selectedOption === "cash"}
                        onChange={handleOptionChange}
                        value="cash"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="black-line-payment-page-box-2"></div>
                  </>
                )}

                  <div
                    className={`container-payment-page-img ${
                      isArabic ? "rtl" : ""
                    }`}
                  >
                    <label
                      className={`container-payment-page ${
                        isArabic ? "rtl" : ""
                      }`}
                    >
                      {t("creditDebit")}
                      <input
                        type="radio"
                        checked={selectedOption === "card"}
                        onChange={handleOptionChange}
                        value="card"
                      />
                      <span className="checkmark"></span>
                    </label>
                    <img
                      src={cardProviders}
                      alt="cardProviders"
                      className="cardProviders"
                    />
                  </div>
                </div>

                <Link to={`/cart/${id}/shipping`}>
                  <div className="Return-Order-Info-Container">
                    <img
                      src={RightArrow}
                      alt="right arrow"
                      width={16}
                      height={16}
                      className={`arrow ${isArabic ? "arabic" : ""}`}
                    />
                    <div className="return-order-info">
                      {t("Return to Shipping")}
                    </div>
                  </div>
                </Link>
              </div>

              {cart && (
                <div className="subtotal-container-payment">
                  <div className="subtotal-container-row">
                    <div className="subtotal-title-row">{t("subtotal")}</div>
                    <div className="subtotal-value-row">
                      {cart.sub_total} {t("SAR")}
                    </div>
                  </div>
                  {cart.points && (
                    <div className="subtotal-container-row">
                      <div className="subtotal-title-row">{t("Discount")}</div>
                      <div className="subtotal-value-row">
                        - {cart.points_price} {t("SAR")}
                      </div>
                    </div>
                  )}
                  {cart.promocode && (
                    <div className="subtotal-container-row">
                      <div className="subtotal-title-row">{t("Discount")}</div>
                      <div className="subtotal-value-row">
                        - {cart.promocode_price} {t("SAR")}
                      </div>
                    </div>
                  )}

                  <div className="subtotal-container-row">
                    <div className="subtotal-title-row">
                      {t("shippingFees")}
                    </div>
                    <div className="subtotal-value-row">
                      {/* {cart.shipping_fees} SAR */}
                      {selectedOption
                        ? cart.shipping_fees === 0
                          ? `${t("Free")}`
                          : `${cart.shipping_fees} ${t("SAR")}`
                        : "-"}
                    </div>
                  </div>

                  <div className="subtotal-container-row">
                    <div className="subtotal-title-row">{t("total")}</div>
                    <div className="subtotal-value-row">
                      {cart.total_price} {t("SAR")}
                    </div>
                  </div>
                  <Button
                    className="pay-Now-button"
                    text={button}
                    onClick={handleSubmit}
                    disabled={!formIsValid}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="pc-payment-page">
        {loading ? (
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        ) : (
          <>
            <div className="Left-Align-payment">
              <div className="mini">
                {loading ? (
                  <div className="loader-overlay">
                    <SyncLoader
                      className="PuffLoader"
                      color="rgb(252, 219, 204)"
                    />
                  </div>
                ) : (
                  <div>
                    <div className="payment-page-container">
                      <Link to={`/cart/${id}`} className="edit-shipping-page">
                        {t("edit")}
                      </Link>
                      <div className="first-box-payment-page">
                        <div className="contact-payment-page">
                          <div className="first-box-title-payment-page">
                            {t("contact")}
                          </div>
                          <div className="first-box-email-payment-page">
                            {responseDatas?.email}
                          </div>
                        </div>
                        <div className="black-line-payment-page"></div>
                        <div className="ship-to-payment-page">
                          <div className="first-box-title-payment-page">
                            {t("shipTo")}
                          </div>
                          <div className="first-box-email-payment-page">
                            {responseDatas?.appartment},{" "}
                            {responseDatas?.address},{" "}{responseDatas?.district},{" "}{responseDatas?.city},{" "}
                            {responseDatas?.country}
                          </div>
                        </div>
                        <div className="black-line-payment-page"></div>
                        <div className="shipping-method-payment-page">
                          <div className="first-box-title-payment-page">
                            {" "}
                            {t("shippingMethod")}
                          </div>
                          <div className="first-box-email-payment-page">
                            {" "}
                            {t("standardShippment")}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="promocode-title-payment-page">
                          {t("promoCode")}
                        </div>

                        <div className="promocode-container-payment-page">
                          <div className="promocode-text-box-cont-payment-page">
                            <input
                              disabled={isSwitchOn || isPromoOn}
                              className="promocode-text-box-payment-page"
                              name="promocode"
                              type="text"
                              placeholder={t("promoCodeOrGift")}
                              value={promoCode.value}
                              onChange={handlePromoCodeChange}
                            />

                            {isPromoOn && (
                              <img
                                src={closeSign}
                                onClick={handleRemovePromo}
                                alt="Close Sign"
                              />
                            )}
                          </div>

                          <div
                            className="promocode-button-payment-page"
                            onClick={handleSubmitPromocode}
                          >
                            {loadingPromo ? (
                              <div className="loader-overlay-payment-page">
                                <ClipLoader
                                  className="ClipLoader"
                                  color="rgb(255, 255, 255)"
                                />
                              </div>
                            ) : (
                              <img
                                src={RightArrow}
                                alt="right arrow"
                                width={16}
                                height={16}
                                className={
                                  i18n.language === "ar" ? "mirror" : ""
                                }
                              />
                            )}
                          </div>
                        </div>
                        {errorPromoPoints && (
                          <div className="promocode-error-message">
                            {t("Can't work with the points")}
                          </div>
                        )}
                        {errorPromo && (
                          <div className="promocode-error-message">
                            {t("OOPS! Coupon invalid")}
                          </div>
                        )}
                      </div>

                      {isUser && (
                        <div className="switch-toogle-container">
                          <div className="switch-toogle">
                            <ReactSwitch
                              onChange={switch_onChange_handle}
                              checked={isSwitchOn}
                              disabled={!isSwitchOn && toogleDisableFlag}
                              offColor={"#f0f0f0"}
                              onColor={"#fff4f0"}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onHandleColor={"#fddccd"}
                              offHandleColor={"#fddccd"}
                              width={30}
                              height={15}
                            />
                            <div className="switch-toogle-text">
                              {t("Redeem Points")}
                            </div>
                          </div>
                          {errorPoints && (
                            <div className="promocode-error-message">
                              {t("You have 0 points")}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="payment-title-payment-page">
                        {t("payment")}
                      </div>
                      <div className="second-box-payment-page"
                      style={!isKSA ? { justifyContent: "center" } : {}}>
                        
                      
                      {isKSA && (
                        <>
                        <div
                          className={`container-payment-page-img ${
                            isArabic ? "rtl" : ""
                          }`}
                        >
                          
                          <label
                            className={`container-payment-page ${
                              isArabic ? "rtl" : ""
                            }`}
                          >
                            {t("COD")}
                            <input
                              type="radio"
                              checked={selectedOption === "cash"}
                              onChange={handleOptionChange}
                              value="cash"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <div className="black-line-payment-page-box-2"></div>
                        </>
                      )}
                        <div
                          className={`container-payment-page-img ${
                            isArabic ? "rtl" : ""
                          }`}
                        >
                          <label
                            className={`container-payment-page ${
                              isArabic ? "rtl" : ""
                            }`}
                          >
                            {t("creditDebit")}
                            <input
                              type="radio"
                              checked={selectedOption === "card"}
                              onChange={handleOptionChange}
                              value="card"
                            />
                            <span className="checkmark"></span>
                          </label>
                          <img
                            src={cardProviders}
                            alt="cardProviders"
                            className="cardProviders"
                          />
                        </div>
                      </div>
                    </div>

                    {cart && (
                      <div className="button-container-payment">
                        <Button
                          className="pay-Now-button"
                          text={button}
                          onClick={handleSubmit}
                          disabled={!formIsValid}
                        />
                      </div>
                    )}
                  </div>
                )}

                <Link to={`/cart/${id}/shipping`}>
                  <div className="Return-Order-Info-Container">
                    <img
                      src={RightArrow}
                      alt="right arrow"
                      width={16}
                      height={16}
                      className={`arrow ${isArabic ? "arabic" : ""}`}
                    />
                    <div className="return-order-info">
                      {t("returnToShipping")}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="Right-Align-payment">
              <div>
                {products &&
                  products.length > 0 &&
                  products.map((product, index) => (
                    <div className="single-product-coloum-payment" key={index}>
                      <div className="single-product-payment">
                        {product.medias && product.medias.length > 0 && (
                          <img
                            src={
                              product.medias.find(
                                (media) => media.tag === "rectangle"
                              ).image_url
                            }
                            alt={product.tag}
                            height={130}
                          />
                        )}

                        <div
                          className={`single-coloum-payment ${
                            isArabic ? "arabic" : ""
                          }`}
                        >
                          <div className="product-name-payment">
                            {isArabic ? product.name_ar : product.name_en}
                          </div>
                          <div className="product-price-payment">
                            {product.status === "Sale"
                              ? product.discountedPrice
                              : product.price}{" "}
                            {t("SAR")}
                          </div>
                          <div className="product-counter-payment">
                            <CounterBox
                              ProductColor={"rgb(252, 219, 204)"}
                              quantity={product.quantity}
                              productId={product.id}
                              productLimit={product.product_limit}
                              onUpdate={(productId, quantity) =>
                                editProductInCart(productId, quantity)
                              }
                            />
                            <div
                              className="product-remove-payment"
                              onClick={() => removeProductFromCart(product.id)}
                              style={{ cursor: "pointer" }}
                            >
                              {t("remove")}
                            </div>
                          </div>
                        </div>
                      </div>
                      {index < products.length && (
                        <img
                          src={BlackLine}
                          alt="blackLine"
                          className="blackLine"
                        />
                      )}
                    </div>
                  ))}
                <div className="subtotal-container-pc">
                  {cart && (
                    <>
                      <div className="row-pc">
                        <div className="title-pc">{t("subtotal")}</div>
                        <div className="value-pc">
                          {cart.sub_total} {t("SAR")}
                        </div>
                      </div>

                      {cart.points && (
                        <div className="row-pc">
                          <div className="title-pc">Discount</div>
                          <div className="value-pc">
                            - {cart.points_price} {t("SAR")}
                          </div>
                        </div>
                      )}
                      {cart.promocode && (
                        <div className="row-pc">
                          <div className="title-pc">Discount</div>
                          <div className="value-pc">
                            - {cart.promocode_price} {t("SAR")}
                          </div>
                        </div>
                      )}

                      <div className="row-pc">
                        <div className="title-pc">{t("shippingFees")}</div>
                        <div className="value-pc">
                          {selectedOption
                            ? cart.shipping_fees === 0
                              ? `${t("Free")}`
                              : `${cart.shipping_fees} ${t("SAR")}`
                            : "-"}
                        </div>
                      </div>

                      <div className="row-pc">
                        <div className="title-pc">{t("total")}</div>
                        <div className="value-pc">
                          {cart.total_price} {t("SAR")}
                        </div>
                      </div>
                    </>
                  )}
                  <img src={BlackLine} alt="blackLine" className="blackLine" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Payment;
