import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./terms.css";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  const termsData = [
    {
      question: "FAQ-Rewards-Question-1",
      answer: "FAQ-Rewards-Answer-1A",
    },
    {
      question: "FAQ-Rewards-Question-2",
      answer: "FAQ-Rewards-Answer-2",
    },
    {
      question: "FAQ-Rewards-Question-3",
      answer: "FAQ-Rewards-Answer-3",
    },
    {
      question: "FAQ-Rewards-Question-4",
      answer: "FAQ-Rewards-Answer-4",
    },
    {
      question: "FAQ-Rewards-Question-5",
      answer: "FAQ-Rewards-Answer-5",
    },
    {
      question: "FAQ-Rewards-Question-6",
      answer: "FAQ-Rewards-Answer-6",
    },
    {
      question: "FAQ-Rewards-Question-7",
      answer: "FAQ-Rewards-Answer-7",
    },
    {
      question: "FAQ-Rewards-Question-8",
      answer: "FAQ-Rewards-Answer-8",
    },
    {
      question: "FAQ-Rewards-Question-9",
      answer: "FAQ-Rewards-Answer-9",
    },
    {
      question: "FAQ-Rewards-Question-10",
      answer: "FAQ-Rewards-Answer-10",
    },
    {
      question: "FAQ-Rewards-Question-11",
      answer: "FAQ-Rewards-Answer-11",
    },
    {
      question: "FAQ-Rewards-Question-12",
      answer: "FAQ-Rewards-Answer-12",
    },
  ];

  return (
    <main>
      <div className="terms-conditions-container">
        <div className="terms-title">{t("Rewards Terms & Conditions")}</div>
        {termsData.map((term, index) => (
          <Accordion
            key={index}
            className="terms-accordion"
            sx={{
              boxShadow: "none",
              border: "none",
              "&:before": { display: "none" },
              "&:not(:last-child)": { marginBottom: "10px" },
              "&.Mui-expanded": { marginBottom: "10px" },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              sx={{ borderBottom: "1px solid #e0e0e0" }}
            >
              {t(term.question)}
            </AccordionSummary>
            <AccordionDetails> {t(term.answer)}</AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Footer />
    </main>
  );
};

export default TermsAndConditions;
